@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}


body h2 {
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.04em;
}

body h5 {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-align: left;
  margin: 0px;
}

body h6 {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-align: left;
  padding: 5px 0px;
  margin: 0px;
}

body p {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.04em;
  text-align: left;
  margin: 0px;
}

body pre {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.04em;
  text-align: left;
  margin: 0px;
  text-wrap: wrap;
}

.ant-layout.layout-default.main-layout.css-dev-only-do-not-override-xu9wm8 {
  background: #fff;
}
.heading{
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-weight: 300;
 
  text-transform: lowercase;
  font-variant: small-caps;
  display: block;
  line-height: 2rem;
  margin: 1rem 0 2rem;
  color: #757575;
}

.custom-scrollbar-2, .custom-scrollbar-3{
  scrollbar-width: thin;
  scrollbar-color: #b1afaf;
}
.custom-scrollbar-2::-webkit-scrollbar, .custom-scrollbar-3::-webkit-scrollbar,  select::-webkit-scrollbar{
  width: 5px;
}
.custom-scrollbar-2::-webkit-scrollbar-thumb,  select::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;
  border-radius: 2px;
}
.custom-scrollbar-3::-webkit-scrollbar-thumb {
  background-color: #545454;
  border-radius: 2px;
}
.custom-scrollbar-2::-webkit-scrollbar-track, .custom-scrollbar-2::-webkit-scrollbar-track {
  background-color: transparent;
}
.ellipsis-anim span {
  opacity: 0;
  -webkit-animation: ellipsis-dot 1s infinite;
  animation: ellipsis-dot 1s infinite;
}
.ellipsis-anim span:nth-child(1) {
  -webkit-animation-delay: 0.0s;
  animation-delay: 0.0s;
}
.ellipsis-anim span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
@-webkit-keyframes ellipsis-dot {
    0% { opacity: 0; }
   50% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes ellipsis-dot {
    0% { opacity: 0; }
   50% { opacity: 1; }
  100% { opacity: 0; }
}
.no-copy {
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

ul, ol {
list-style: list-style-type !important;
}

.primary {
  background-color: #0383BB !important;
  border-radius: 4px;
  
}







