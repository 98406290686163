.main-header {
    background-color: #fff;
}

.top-header {
    border-bottom: 1px solid #bababa;
    padding: 20px 0px;
}

.main-header .header  ul li a , .main-header .top-header  ul li a, .header-drawer ul li a {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
}
.header-drawer{
margin-left: 10px;
}


.main-header .top-header ul , .main-header .header  ul, .header-drawer ul{
    border: none !important;
}



.ant-menu-item-active::after, .ant-menu-item-selected::after {
    border: none !important;
}

.ant-menu-item-active a, .ant-menu-item-selected a {
    color: #0083BB !important;
}
.ant-menu-item-active , .ant-menu-item-selected {
    background: transparent !important;
}

.ant-menu-item-selected {
    border: none !important;
}