.main-footer {
    background: #264581;
    margin-top: 60px;
}

.footer {
    margin: 40px 0px 50px 0px;
}

.main-footer .footer .social-links {
    margin: 0px;
    padding: 0px;
}

.main-footer .footer .social-links li {
    display: inline-block;
}
.main-footer .footer .social-links li a{
    background: rgb(255 255 255 / 25%);
    padding: 12px 20px;
    margin: 0px 4px;
    border-radius: 5px;
}

.main-footer .bottom-bar p, .main-footer h6 {
    color: #fff;
}

.main-footer .bottom-bar {
    border-top: 1px solid #fff;
    padding: 20px 0px;
}
.bottom-bar p a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

