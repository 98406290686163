.case-report-form {
    margin-top: 60px;
}

.case-report-left {
    margin-right: 32px;
}

.case-report-left .ant-form-item.css-dev-only-do-not-override-xu9wm8 {
    margin: 10px;
}

.case-report-left form.ant-form input,
.case-report-left form.ant-form textarea,
.case-report-left form.ant-form .ant-select-selector {
    background: #f1f2f6;
    border: none;
    padding: 10px;
}

.case-report-left form.ant-form button {
    margin-top: 10px;
}

.case-report-left form.ant-form button {
    background-color: #0083BB;
}

.case-report-left form.ant-form button span {
    padding: 0px 28px;
}

.case-report-left p strong {
    font-size: 16px;
}
.case-report-form
.result-button {
    position: absolute;
    bottom: 33px;
    right: 0;
    width: 50%;
}
.result-button button {
    background-color: #0083BB;
}
.case-report-notes ol li {
    padding: 10px 0px 0px 0px;
}
@media only screen and (min-width : 320px) and (max-width : 767px) {
    .result-button {
        position: static;
        width: 100%;
        display: flex !important;
        justify-content: space-between;
    }
    :where(.css-dev-only-do-not-override-xu9wm8).ant-col-xs-24 {
        display: contents;
    }
}
@media only screen and (min-width : 767px) and (max-width : 1279px) {
    .result-button {
        position: absolute;
        bottom: 33px;
        right: 0;
        width: 100%;
    }
}