.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#alert-trigger{
  display:none !important;
  width:0px !important;
  height:0px !important;
  -webkit-animation:"none"! important;
  animation:"none" !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-checked .ant-checkbox-inner {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}

.custom-checked-two .ant-checkbox-inner {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}

.custom-unchecked .ant-checkbox-inner {
  background-color: #fff !important;
  border-color: #1890ff !important;
}